<template>
  <div class="container">
    <pre>{{value}}</pre>
  </div>
</template>

<script>

export default {
  data() {
    return {
      value: 'test'
    };
  },
};

</script>
